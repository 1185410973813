<template>
  <v-data-table
    :headers="cabecera"
    :items="temporada"
    item-key="id"
    sort-by="fecha"
    :sort-desc="ordenDescendente"
    class="elevation-3 my-15"
    dense
    mobile-breakpoint="360"
    :footer-props="{
      itemsPerPageText: '',
      disableItemsPerPage: true,
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-select
            v-model="temp"
            :items="combo"
            label="Temporada"
            dense
            solo
            class="mt-5"
          ></v-select>
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-btn :to="{ name: 'crearArticulo' }" x-small fab dark color="green">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>

        <!-- diálogo editar -->

        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span>{{ dialogTitulo }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6" md="4">
                    <v-text-field
                      v-model="editRecogida.fecha"
                      label="Fecha"
                      type="date"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="4">
                    <v-text-field v-model="editRecogida.qr" label="Árbol">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <!-- <v-slider
                      v-model="kg"
                      color="brown darken-1"
                      track-color="brown lighten-3"
                      min="0"
                      max="5"
                      step="0.1"
                      prepend-icon="mdi-cube"
                      class="align-center"
                      thumb-label="always"
                    >

                        

                    </v-slider>
                    <v-slider
                      v-model="dg"
                      color="green darken-1"
                      track-color="green lighten-3"
                      min="0"
                      max="99"
                      step="1"
                      prepend-icon="mdi-cube"
                      class="align-center"
                      thumb-label="always"
                    >
                    </v-slider>
                    <h4 class="text-right">{{kilos}}</h4> -->
                    <v-text-field v-model="editRecogida.gramos" label="Gramos">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialogo eliminar -->

        <v-dialog v-model="dialogBorrar">
          <v-card>
            <v-card-title>
              <span class="headline">Borrar registro</span>
            </v-card-title>
            <v-card-text>
              {{ dialogBorrarText }}
            </v-card-text>
            <v-card-actions>
              <v-btn color="green" @click="confirmarBorrado()">Aceptar</v-btn>
              <v-btn
                color="gray"
                @click="
                  id = false;
                  dialogBorrar = false;
                "
                >Cancelar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.acciones`]="{ item }">
      <v-icon small class="mr-5" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <!--  <v-btn color="primary" @click="obtenerRecogidas"> Reset </v-btn> -->
      <v-btn color="primary"> No hay datos </v-btn>
    </template>
    <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
      <td :colspan="headers.length">
        <v-btn @click="toggle" x-small icon :ref="group">
          <v-icon v-if="isOpen">mdi-plus</v-icon>
          <v-icon v-else>mdi-minus</v-icon>
        </v-btn>
        <span class="mx-5 font-weight-bold">{{ group }}</span>
      </td>
    </template>
    <template v-slot:[`item.fecha`]="{ item }">
      {{ item.fecha | fechaFormateada }}
    </template>
  </v-data-table>
</template>

<script>
import axios from "../api/axios";
import { mapState, mapActions } from "vuex";
export default {
  name: "listarArticulos",
  mounted() {
    //this.obtenerRecogidas();
    const fecha = new Date();
    let anio = new Date(fecha.setMonth(fecha.getMonth() - 6)).getFullYear();
    this.temp = anio;
  },
  data() {
    return {
      cabecera: [
        {
          id: "id",
          text: "Fecha",
          align: "start",
          groupable: false,
          value: "fecha",
        },
        {
          text: "Gramos",
          value: "gramos",
          align: "end",
          groupable: false,
          sortable: false,
        },
        { text: "Arbol", value: "QR", groupable: false },
        { text: "", value: "acciones", sortable: false, groupable: false },
      ],
      editRecogida: {
        fecha: "",
        gramos: 0,
        qr: "",
      },
      kg: 0,
      dg: 0,
      ordenDescendente: true,
      id: null,
      snackbar: false,
      textsnack: "¡Registro eliminado!",
      dialog: false,
      dialogTitulo: "Editar Recogida",
      dialogBorrar: false,
      dialogBorrarText: "",
      elOrigen: "recogidas",

      temp: null,
    };
  },
  computed: {
    ...mapState(["token"]),
    /* kilos(){
      return parseFloat(this.kg).toFixed(1) + `${this.dg}`.padStart(2, 0);
    }, */
    logueado() {
      return this.$store.state.logueado;
    },
    delDia() {
      const hoy = new Date().setHours(0, 0, 0, 0);
      return this.recogidas.filter((r) => {
        const fec = new Date(r.fecha).setHours(0, 0, 0, 0);
        return fec.valueOf() == hoy.valueOf();
      });
    },
    recogidas() {
      return this.$store.state.recogidas;
    },
    temporada() {
      return this.recogidas.filter((r) => {
        //const fch = new Date(r.fecha);
        //const fch6 = new Date(fch.setMonth(fch.getMonth() - 6));
        //const yr = fch6.getFullYear();
        //console.log(yr);
        //return r.fecha.substr(0, 4) == this.temp;
        //return yr == this.temp;
        return r.Temporada == this.temp;
      });
    },
    combo() {
      return this.$store.state.temporadas;
    },
  },
  filters: {
    fechaFormateada(fecha) {
      return new Intl.DateTimeFormat("es-ES", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(new Date(fecha));
    },
  },
  methods: {
    ...mapActions(["caducaToken"]),
    /* obtenerRecogidas() {
      if (this.logueado) {
        const esto = this;
        axios
          .get("/recogidas")
          .then((res) => {
            this.recogidas = res.data;
            this.recogidas.forEach(
              (item) => (item.fecha = item.fecha.substring(0, 10))
            );
          })
          .catch(function (error) {
            console.log(error);
            esto.$store.dispatch("caducaToken");
            esto.$router.push("/");
          });
      } else {
        this.$router.push("/");
      }
    }, */
    confirmarBorrado() {
      //console.log("conf", this.id);
      this.$store
        .dispatch("borrarRecogida", this.id)
        .then(() => {
          //this.obtenerRecogidas();
          this.dialogBorrar = false;
          this.snackbar = true;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    editItem(item) {
      //
      //console.log(item.kilos.toString());
      this.id = item.id;
      this.editRecogida.fecha = item.fecha;
      this.editRecogida.gramos = item.gramos;
      /* this.kg = item.kilos.toString().substring(0, 3);
      this.dg = item.kilos.toString().substring(3, 6); */

      this.editRecogida.qr = item.QR;
      //console.log(this.kg, this.dg);
      this.dialog = true;
    },
    deleteItem(item) {
      //
      this.dialogBorrarText = `¿Estás seguro de que quieres eliminar el registro de fecha ${item.fecha} y arbol ${item.QR} de la base de datos?`;
      this.id = item.id;
      this.dialogBorrar = true;
    },
    save() {
      axios
        .put(`/recogidas/${this.id}`, {
          fecha: this.editRecogida.fecha,
          gramos: this.editRecogida.gramos,
          QR: this.editRecogida.qr,
        })
        .then(() => {
          this.dialog = false;
          //this.obtenerRecogidas();
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          this.id = "";
          //this.obtenerRecogidas();
          this.$store.dispatch("obtenerRecogidas");
        });
    },
  },
};
</script>