<template>
  <div class="container float py-15">
    <v-select
      v-model="temp"
      :items="combo"
      label="Temporada"
      dense
      solo
      color="brown darken-1"
      item-color="brown darken-1"
      class="mt-5 mb-0 mx-3"
    ></v-select>

    <v-card class="mx-3 mb-5">
      <v-card-title>Totales</v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between mb-1">
          <span class="font-weight-medium">Gramos recogidos:</span>
          <span class="mr-5">{{ grTotales[0] }}</span>
        </div>
        <div class="d-flex justify-space-between mb-1">
          <span class="font-weight-medium">Recogidas:</span>
          <span class="mr-5">{{ grTotales[1] }}</span>
        </div>
        <div class="d-flex justify-space-between mb-1">
          <span class="font-weight-medium">Días:</span>
          <span class="mr-5">{{ diasTotales }}</span>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mx-3 px-1 pb-3">
      <v-card-title>Recogidas por día</v-card-title>
      <line-chart :chart-data="datacollection" :options="options"></line-chart>
    </v-card>

    <v-card class="mx-3 my-5">
      <v-card-title>Datos por árbol</v-card-title>
      <v-data-table
        :headers="cabecera"
        sort-by="gramos"
        :sort-desc="true"
        :items="arboles"
        :items-per-page="10"
        class="mx-3"
        dense
        mobile-breakpoint="360"
      ></v-data-table>
    </v-card>
    <h2 class="mt-3 mb-2"></h2>
  </div>
</template>

<script>
import LineChart from "../graphs/LineChart.js";

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: {},
      data: [],
      arboles: [],
      cabecera: [
        {
          text: "Árbol",
          align: "start",
          groupable: false,
          sortable: true,
          value: "arbol",
        },
        {
          text: "Gramos",
          value: "gramos",
          align: "end",
          groupable: false,
          sortable: true,
        },
        {
          text: "Recogidas",
          value: "recogidas",
          align: "end",
          groupable: false,
          sortable: true,
        },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
      },

      temp: null,
    };
  },
  mounted() {
    //Temporada actual
    const fecha = new Date();
    let anio = new Date(fecha.setMonth(fecha.getMonth() - 6)).getFullYear();
    this.temp = anio;

    //Datos para el gráfico por día
    //this.data = Object.values(this.groupBy(this.deTemporada, "fecha"));
    //this.arboles = Object.values(this.byArbol(this.deTemporada));
    this.datosDia();
    //Pasa los datos al gráfico
    this.fillData();
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: this.data.map((x) =>
          new Intl.DateTimeFormat("es-ES", {
            month: "short",
            day: "numeric",
          }).format(new Date(x.date))
        ),
        datasets: [
          {
            label: "Gramos recogidos",
            fill: false,
            lineTension: 0.1,
            borderColor: "brown",
            pointBorderColor: "green",
            pointRadius: 6,
            pointBackgroundColor: "white",
            borderWidth: 3,
            data: this.data.map((x) => x.gramos),
          },
        ],
      };
    },
    datosDia() {
      //Datos para el gráfico por día
      this.data = Object.values(this.groupBy(this.deTemporada, "fecha")).sort(
        function (a, b) {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
      );
      this.arboles = Object.values(this.byArbol(this.deTemporada));
    },
    groupBy(miarray, prop) {
      return miarray.reduce(function (groups, item) {
        var val = item[prop];
        groups[val] = groups[val] || { date: item.fecha, gramos: 0 };
        groups[val].gramos += item.gramos;
        return groups;
      }, {});
    },

    byArbol(miarray) {
      return miarray.reduce(function (acum, item) {
        let val = item["QR"];
        acum[val] = acum[val] || { arbol: item.QR, gramos: 0, recogidas: 0 };
        acum[val].gramos += item.gramos;
        acum[val].recogidas++;
        return acum;
      }, []);
    },

    //deTemporada(){}
  },

  computed: {
    recogidas() {
      return this.$store.state.recogidas;
    },
    deTemporada() {
      return this.recogidas.filter((r) => {
        return r.Temporada == this.temp;
      });
    },
    combo() {
      //console.log("c");
      return this.$store.state.temporadas;
    },
    grTotales() {
      return this.deTemporada.reduce(
        (acc, item) => {
          const ac = [(acc[0] += item.gramos), acc[1] + 1];
          return ac;
        },
        [0, 0]
      );
    },
    diasTotales() {
      return new Set(this.deTemporada.map((item) => item.fecha)).size;
    },
  },
  watch: {
    temp: function () {
      //console.log("watch");
      this.datosDia();
      this.fillData();
    },
  },
};
</script>

<style>
.small {
  max-width: 370px;
  margin: 150px auto;
}
</style>