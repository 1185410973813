<template>
  <v-container fill-height>
    <v-row class="d-flex">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            max-width="300px"
            class="mx-auto pa-2"
            :elevation="hover ? 24 : 6"
          >
            <v-card-title class="my-0">
              <v-row class="text-center">
                <h2 class="h2 mx-auto">Login</h2>
              </v-row>
            </v-card-title>
            <v-card-text>
              <form
                @submit.prevent="
                  login(user);
                  $router.push('/');
                "
              >
                <v-row class="my-0">
                  <v-text-field
                    v-model="user.usuario"
                    label="login"
                    color="brown"
                    outlined
                    dense
                    required
                    autofocus
                  ></v-text-field>

                  <v-text-field
                    v-model="user.password"
                    label="password"
                    type="password"
                    color="brown"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>

                  <v-card-actions class="mx-auto">
                    <v-btn class="" color="green" type="submit">Enviar</v-btn>
                  </v-card-actions>
                </v-row>
              </form>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      user: {
        usuario: "",
        password: "",
      },
    };
  },
  //create(){
  //  console.log(process.env);
  //},
  methods: {
    ...mapActions(["login"]),
  },
};
</script>