<template>
  <v-container fill-height>
    <v-row class="text-center" :wrap="true">
      <v-col class="mb-4" cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="180"
        />
        <h1 class="display-2 font-weight-bold mb-3">App Trufas</h1>
        <h1 class="display-5 font-weight-bold mb3">2020</h1>
      </v-col>
      <v-col>

        {{url}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'inicio',
  data(){
    return{
      url: ''
    }
  },
  mounted() {
    this.url = process.env.URL_API;
  },
  created(){
    this.$store.dispatch("caducaToken");
  },
  methods: {
    ...mapActions(["caducaToken"])
  },
}
</script>
  
