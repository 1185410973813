import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import VueRouter from 'vue-router';
//import VueQrcodeReader from 'vue-qrcode-reader';

// importamos nuestros componentes
import inicio from './components/Inicio';
import login from './components/Login';
import listarArticulos from './components/ListarArticulos';
import crearArticulo from './components/CrearArticulo';
import contacto from './components/Contacto';
import listarCero from './components/ListarCero';
import listarDia from './components/ListarDia';
import estadisticas from './components/Estadisticas';



//creamos nuestros componentes
Vue.component('inicio', inicio);
Vue.component('login', login);
Vue.component('listarArticulos', listarArticulos);
Vue.component('crearArticulo', crearArticulo);
Vue.component('contacto', contacto);
Vue.component('listarCero', listarCero);
Vue.component('listarDia', listarDia);
Vue.component('estadisticas', estadisticas)

//Vue.use(VueQrcodeReader);
Vue.use(VueRouter);


/* Definimos las rutas */
const routes = [
  {path: '/', component:inicio},
  {path: '/inicio', component:inicio},
  {path: '/login', component:login},
  {path: '/recogidas' , component:listarArticulos, meta: {app: true}},
  {path: '/crear', component:crearArticulo, name: 'crearArticulo', meta: {app: true}},
  {path: '/contacto', component:contacto},
  {path: '/listarcero', component:listarCero},
  {path: '/listardia', component:listarDia},
  {path: '/estadisticas', component:estadisticas}
]

const router = new VueRouter({
  routes,
  mode:'history'
})


router.beforeEach((to, from, next) =>{
  const esApp = to.matched.some(item => item.meta.app)
  if (esApp && store.state.token===null){
    next('/')
  } else {
    next()
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
