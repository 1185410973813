<template>
  <v-container>
    <v-row class="text-center mt-10">
      <v-col class="pb-0">
        <h2 class="h2">Recogida de trufas</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <form v-on:submit.prevent="guardarRecogida()">
          <v-row class="mx-0">
            <v-col cols="8" sm="5">
              <v-text-field
                v-model="recogida.qr"
                label="Árbol"
                color="brown"
                outlined
                dense
                required
                ref="qr"
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="2">
              <v-btn
                color="green"
                class="ml-1"
                @click="
                  dialog = true;
                  camera = 'auto';
                "
                >QR<v-icon right>mdi-qrcode-scan</v-icon></v-btn
              >
            </v-col>
            <v-col>
              <v-text-field
                v-model="recogida.fecha"
                label="Fecha"
                type="date"
                color="brown"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="mr-4">
              <v-btn
                icon
                color="black"
                v-if="fechaLock"
                @click="fechaLock = false"
              >
                <v-icon dark> mdi-lock </v-icon>
              </v-btn>
              <v-btn
                icon
                color="grey"
                v-if="!fechaLock"
                @click="fechaLock = true"
              >
                <v-icon dark> mdi-lock-open </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-col>
            <!-- <v-slider
              v-model="kg"
              color="brown darken-1"
              track-color="brown lighten-3"
              min="0"
              max="5"
              step="0.1"
              prepend-icon="mdi-cube"
            >
            </v-slider>
            <v-slider
              v-model="dg"
              color="green darken-1"
              track-color="green lighten-3"
              min="0"
              max="99"
              step="1"
              prepend-icon="mdi-cube"
            >
            </v-slider>
             <v-container>
          </v-container>
          <h4 class="text-right">{{kilos}} </h4> -->
            <v-text-field
              v-model.number="recogida.gramos"
              label="Gramos"
              type="number"
              color="brown"
              outlined
              dense
              required
            >
            </v-text-field>
          </v-col>
          <v-row> </v-row>
          <v-card-actions class="mb-10">
            <v-btn class="ml-auto" color="green" type="submit">Guardar </v-btn>
            <v-btn color="error" @click="salir">Salir</v-btn>
          </v-card-actions>
        </form>
      </v-col>
    </v-row>
    <v-row> </v-row>

    <!-- Diálogo Cámara -->
    <v-dialog v-model="dialog" fullscreen>
      <v-card max-width="500" class="mx-auto">
        <v-toolbar dense :color="barraColor">
          <v-btn
            icon
            @click="
              dialog = false;
              camera = 'off';
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            {{ decodedContent }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p class="error">
            {{ errorMessage }}
          </p>

          <vue-qr-reader
            :stop-on-scanned="stopOnScanned"
            v-on:code-scanned="onDecode"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Diálogo QR no existe -->
    <v-dialog v-model="dialog2">
      <v-card>
        <v-card-title>
          {{ dialogTitulo }}
        </v-card-title>
        <v-card-text>
          {{ dialogTexto }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="brown" outlined @click="dialog2 = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
      {{ snacktext }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "../api/axios";
import VueQrReader from "vue-qr-reader/dist/lib/vue-qr-reader.umd.js";
//import Instascan from "instascan";
//import { QrcodeStream } from "../../public/VueQrcodeReader.umd.min.js";
//import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  components: { VueQrReader },
  name: "crearArticulo",
  data() {
    return {
      recogida: {
        fecha: new Date().toISOString().substr(0, 10),
        gramos: 0,
        qr: "",
      },
      kg: 0,
      dg: 0,
      gramos: 0,
      decodedContent: "",
      errorMessage: "",
      dialog: false,
      loading: false,
      destroyed: false,
      camera: "auto",
      stopOnScanned: false,
      barraColor: "primary",
      dialog2: false,
      dialogTitulo: "",
      dialogTexto: "",
      snackbar: false,
      snacktext: "",
      snackcolor: "green",
      timeout: 3000,
      foottext: "",
      fechaLock: false,
    };
  },
  computed: {
    /* kilos() {
      return this.kg.toFixed(1)+`${this.dg}`.padStart(2,0)
    } */
    Temporada() {
      const fecha = new Date();
      let despues = new Date(fecha.setMonth(fecha.getMonth() - 6));
      return despues.getFullYear();
    },
  },
  methods: {
    guardarRecogida() {
      // aquí hay que mirar si existe el arbol
      const esto = this;
      axios
        .get("/arboles/" + this.recogida.qr)
        .then((res) => {
          //console.log(res.data);
          if (res.data.length > 0) {
            //var router = this.$router;
            console.log(
              this.recogida.fecha,
              this.recogida.gramos,
              this.recogida.qr,
              this.Temporada
            );
            axios
              .post("/recogidas", {
                fecha: this.recogida.fecha,
                gramos: this.recogida.gramos,
                QR: this.recogida.qr,
                Temporada: this.Temporada,
              })
              .then((res) => {
                console.log(res);
                if (res.error) {
                  this.dialogTitulo = "Recogida existente";
                  this.dialogTexto =
                    "Ya existe una recogida para ese día. Si quieres cambiar el peso puedes hacerlo a través del listado";
                  this.dialog2 = true;
                }
                //router.push("/recogidas");
                // Limpia recogida
                if (!this.fechaLock) {
                  this.recogida.fecha = new Date().toISOString().substr(0, 10);
                }
                this.recogida.gramos = 0;
                this.recogida.qr = "";
                //this.limpiarRecogida()
                this.$store.dispatch("obtenerRecogidas");
                this.snacktext = "Guardado";
                this.snackcolor = "green";
                this.snackbar = true;
              })
              .catch(function (error) {
                console.log(error.response.data.message);
                esto.snacktext = error.response.data.message || error;
                esto.snackcolor = "brown";
                esto.snackbar = true;
                /* console.log(error);
                if (
                  error.response.data.message ==
                  "Ya existe una recogida para ese arbol ese día"
                ) {
                  esto.dialogTitulo = "Recogida existente";
                  esto.dialogTexto =
                    "Ya existe una recogida para ese día. Si quieres cambiar el peso puedes hacerlo a través del listado";
                  esto.dialog2 = true;
                  esto.limpiarRecogida()
                } */
              });
          } else {
            //console.log("No exisite");
            this.dialogTitulo = "Código inexistente";
            this.dialogTexto =
              "El código introducido no corresponde a esta aplicación.";
            this.dialog2 = true;
            this.recogida.qr = "";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    salir() {
      var router = this.$router;
      router.push("/recogidas");
    },
    codeArrived(code) {
      console.log(code);
    },
    onDecode(content) {
      //console.log("content: ", content);
      if (content.length != 0) {
        if (content.length < 12) {
          // aquí hay que mirar si existe el arbol
          axios.get("/arboles/" + content).then((res) => {
            if (res.data.length > 0) {
              this.recogida.qr = content;
              this.dialog = false;
              this.reload;
              this.barraColor = "primary";
            } else {
              this.decodedContent = `${content} no exite en la base de datos`;
              this.barraColor = "error";
            }
          });
        } else {
          this.barraColor = "accent";
          this.decodedContent = `${content} tiene un formato incorrecto`;
        }
      }
    },

    async reload() {
      this.destroyed = true;

      await this.$nextTick();

      this.destroyed = false;
    },
    limpiarRecogida() {
      if (!this.fechaLock) {
        this.recogida.fecha = new Date().toISOString().substr(0, 10);
      }
      this.recogida.gramos = 0;
      this.recogida.qr = "";
    },
  },
};
</script>