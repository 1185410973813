<template>
  <div>
  <v-data-table
    dense
    mobile-breakpoint="360"
    disable-pagination
    hide-default-footer
    :headers="headers"
    :items="delDia"
    class="elevation-1"
  >
    <template v-slot:[`item.gramos`]="props">
      <v-edit-dialog
        :return-value.sync="props.item.gramos"
        @save="save(props.item)"
        @cancel="cancel"
        @open="open(props.item.gramos)"
        @close="close"
      >
        <div :class="getColor(props.item.gramos)">
          {{ props.item.gramos }}
        </div>
        <template v-slot:input>
          <v-text-field
            v-model="props.item.gramos"
            :rules="[max25chars]"
            label="Gramos"
            single-line
            required
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>My CRUD</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <!-- <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">¿Seguro que lo quieres eliminar?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <!-- <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon> -->
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
  import axios from "../api/axios";

  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      max25chars: v => !! v || 'Entrada incorrecta!',
      headers: [
        {
          text: 'Arbol',
          align: 'start',
          sortable: false,
          value: 'QR',
        },
        { text: 'Peso', value: 'gramos', height: 5 },
        { text: 'Borrar', align: 'center', value: 'actions', sortable: false },
      ],
      desserts: [],
      recogidas: [],
      id: null,
      editedIndex: -1,
      oldGramos : undefined,
      editRecogida: {
        fecha: "",
        gramos: 0,
        qr: ""
      },
      /* defaultItem: {
        kilos: 0,
      }, */
    }),

    computed: {
      /* formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      }, */
      logueado() {
        return this.$store.state.logueado;
      },
      delDia() {
        const hoy = new Date().setHours(0,0,0,0)
        return this.recogidas.filter((r)=>{ 
          const fec = new Date(r.fecha + "Z").setHours(0,0,0,0)
          return fec.valueOf() == hoy.valueOf()})
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.obtenerRecogidas()
    },

    methods: {
      initialize () {
        this.desserts = [
          {
            arbol: '01-01-001',
            fecha: 159,
            createdAt: 24,
            gramos: 35,
          },
          {
            arbol: '01-01-002',
            fecha: 159,
            createdAt: 24,
            gramos: 20,
          },
          {
            arbol: '01-01-003',
            fecha: 159,
            createdAt: 24,
            gramos: 45,
          },
          {
            arbol: '01-01-004',
            fecha: 159,
            createdAt: 24,
            gramos: 120,
          },
          {
            arbol: '01-01-005',
            fecha: 159,
            createdAt: 24,
            gramos: 0,
          },
          {
            arbol: '01-01-006',
            fecha: 159,
            createdAt: 24,
            gramos: 652,
          },
          {
            arbol: '01-01-007',
            fecha: 159,
            createdAt: 24,
            gramos: 112,
          },
          {
            arbol: '01-01-008',
            fecha: 159,
            createdAt: 24,
            gramos: 625,
          },
          {
            arbol: '01-01-009',
            fecha: 159,
            createdAt: 24,
            gramos: 80,
          },
          {
            arbol: '01-01-010',
            fecha: 159,
            createdAt: 24,
            gramos: 43,
          },
        ]
      },
      obtenerRecogidas() {
      if (this.logueado) {
        const esto = this;
        axios
          .get("/recogidas")
          .then((res) => {
            this.recogidas = res.data;
            this.recogidas.forEach(
              (item) => {
                item.fecha = item.fecha.substring(0, 10)
              }
            );
          })
          .catch(function (error) {
            console.log(error);
            esto.$store.dispatch("caducaToken");
            esto.$router.push("/");
          });
      } else {
        this.$router.push("/");
      }
    },

      /* editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      }, */

      deleteItem (item) {
        /* this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item) */
        this.id = item.id
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        axios
        .delete(`/recogidas/${this.id}`)
        .then(() => {
          //this.obtenerRecogidas();
          this.dialogBorrar = false;
          this.snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.id = "";
            this.obtenerRecogidas();
          })
        });
        /* this.desserts.splice(this.editedIndex, 1)
        this.closeDelete() */
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save (it) {
        /* if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          //if (this.editedItem)
          this.desserts.push(this.editedItem)
        }
        console.log('save', this.editedItem)
        this.close() */
        console.log('save', it)
        axios
        .put(`/recogidas/${it.id}`, {
          fecha: it.fecha,
          gramos: parseInt(it.gramos),
          QR: it.QR,
        })
        .then(() => {
          this.dialog = false;
          //this.obtenerRecogidas();
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.id = "";
          this.obtenerRecogidas();
        });
      },

      open(gramos){
        this.oldGramos = gramos
        console.log(gramos)
      },

      close () {
        /* this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }) */
      },

      cancel(){
        this.oldKg = undefined
      },

      getColor (gramos) {
        if (gramos == 0) return 'red--text'
        //else return 'white'
      },
    },
  }
</script>