import Vue from "vue";
import Vuex from "vuex";
import axios from "../api/axios";
//import { createStore } from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    logueado: false,
    recogidas: [],
    temporadas: [],
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    logout(state) {
      state.logueado = false;
    },
    log(state) {
      state.logueado = true;
    },
    setRecogidas(state, payload) {
      state.recogidas = payload;
    },
    setTemporadas(state) {
      state.temporadas = state.recogidas.reduce(function(accu, item) {
        const fecha = new Date(item.fecha);
        let despues = new Date(fecha.setMonth(fecha.getMonth() - 6));
        let anio = despues.getFullYear();
        if (!accu.includes(anio)) {
          accu.push(anio);
        }
        return accu;
      }, []);
    },
  },
  actions: {
    login({ commit, dispatch }, usuario) {
      axios
        .post("/auth/login", usuario)
        .then((res) => {
          //console.log("res:", res);
          if (res.status == 200) {
            commit("setToken", res.data.data.token);
            localStorage.setItem("token", res.data.data.token);
            commit("log");
            dispatch("obtenerRecogidas");
          }
        })
        .catch((error) => {
          console.error(error);
          commit("logout");
        });
    },
    leerToken({ commit }) {
      if (localStorage.getItem("token")) {
        commit("setToken", localStorage.getItem("token"));
      } else {
        commit("setToken", null);
      }
    },
    caducaToken({ commit }) {
      axios
        .get("/auth/admin")
        .then((res) => {
          if (res.status == 200) {
            console.log("");
          }
        })
        .catch((error) => {
          console.error(error);
          commit("setToken", null);
          commit("logout");
        });
    },
    obtenerRecogidas({ commit, dispatch }) {
      axios
        .get("/recogidas")
        .then((res) => {
          const data = res.data;
          data.forEach((item) => (item.fecha = item.fecha.substring(0, 10)));
          commit("setRecogidas", data);
          //console.log(data);
          commit("setTemporadas");
        })
        .catch(function(error) {
          console.log(error);
          dispatch("caducaToken");
        });
    },
    borrarRecogida({ dispatch }, id) {
      axios
        .delete(`/recogidas/${id}`)
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => {
          dispatch("obtenerRecogidas");
        });
    },
  },
  modules: {},
});
