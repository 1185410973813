<template>
  <v-app>
    <!-- <v-card class=" overflow-hidden"> -->

    <!-- <v-system-bar fixedcolor="brown darken-4"></v-system-bar> -->
    <v-app-bar fixed elevate-on-scroll color="brown darken-1" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="60"
          :to="{ path: '/' }"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="logueado" icon>
        <v-icon @click="logout" color="green darken-2">mdi-tree</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      class="brown lighten-5"
    >
      <v-list-item class="brown darken-2" dark>
        <v-list-item-avatar>
          <v-img src="./assets/logo.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title"> Trufas </v-list-item-title>
          <v-list-item-subtitle v-if="logueado"> logued </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="brown--text text--accent-4"
        >
          <v-list-item :to="{ path: '/' }">
            <v-icon>mdi-{{ icons[0] }}</v-icon>
            <v-list-item-content>Inicio</v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!logueado" :to="{ path: '/login' }">
            <v-icon>mdi-{{ icons[3] }}</v-icon>
            <v-list-item-content>Login</v-list-item-content>
          </v-list-item>

          <v-list-item v-if="logueado" :to="{ path: '/recogidas' }">
            <v-icon>mdi-{{ icons[1] }}</v-icon>
            <v-list-item-content>Recogidas</v-list-item-content>
          </v-list-item>

          <v-list-item v-if="logueado" :to="{ path: '/listarcero' }">
            <v-icon>mdi-{{ icons[4] }}</v-icon>
            <v-list-item-content>Sin peso</v-list-item-content>
          </v-list-item>

          <v-list-item v-if="logueado" :to="{ path: '/estadisticas' }">
            <v-icon>mdi-{{ icons[5] }}</v-icon>
            <v-list-item-content>Estadísticas</v-list-item-content>
          </v-list-item>

          <!-- <v-list-item :to="{ path: '/listardia' }">
            <v-icon>mdi-{{ icons[4] }}</v-icon>
            <v-list-item-content>Lista de Hoy</v-list-item-content>
          </v-list-item> -->

          <v-list-item :to="{ path: '/contacto' }">
            <v-icon>mdi-{{ icons[3] }}</v-icon>
            <v-list-item-content>Contacto</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- </v-card> -->
    <v-main class="brown lighten-5">
      <!-- <v-container fluid> -->
      <!-- <v-fade-transition mode="out-in"> -->
      <router-view></router-view>
      <!-- </v-fade-transition> -->
      <!-- </v-container> -->
    </v-main>
    <v-footer fixed dark padless>
      <v-card class="flex" flat tile>
        <v-card-title class="brown darken-2 py-1"> </v-card-title>
        <v-card-text class="py-2 white--text text-center brown darken-4">
          {{ new Date().getFullYear() }} - <strong>Trufas</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
    icons: ["home", "shovel", "tree", "account", "weight", "chart-bar"],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    ...mapActions(["leerToken"]),
    logout() {
      this.$store.commit("setToken", null);
      this.$store.commit("logout");
      this.$router.push("/");
    },
  },

  computed: {
    ...mapState(["token"]),
    logueado() {
      return this.$store.state.logueado;
    },
  },

  created() {
    this.leerToken();
  },
};
</script>