import axios from "axios";
//import { router } from '../main';

//baseURL: 'http://trufas.duckdns.org/api'
//baseURL: 'http://192.168.1.13:8000/api'
const instance = axios.create({
  baseURL: "http://trufas.duckdns.org/api",
  headers: { "cache-control": "no-cache" },
});

instance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token && token !== "") {
      config.headers = {
        "x-access-token": token,
      };
    } else {
      config.headers = {};
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

/* instance.interceptors.response.use(
  function(response) {
  console.log("Interceptor")
  return response;
}, (error) => {
      if(error.response.status !== 401) {
           //add your code
           return Promise.reject(error.message);
      }

      localStorage.setItem("token", null);
      //router.push({name: 'inicio'});

}); */

export default instance;
