<template>
  <v-container fill-height>

    <v-row class="d-flex text-center">
      
      <v-col class="mx-auto pa-2">
            <h2 class="display-2 font-weight-bold mb-3">Contacto</h2>
        <v-card class="text-center pa-5" color="brown lighten-4"> 
          
        <v-icon left color="green darken-1">mdi-email</v-icon>
        <span class="brown--text">luis.atance.perez97@gmail.com</span>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'contacto',
  created(){
    this.$store.dispatch("caducaToken");
  },
  methods: {
    ...mapActions(["caducaToken"])
  },
}
</script>